
import { defineComponent } from 'vue';
import axios from 'axios';
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
// import TableHeaderChange from "@/layout/header/partials/trainning_institute/TableHeaderChange.vue";
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainning-institute-dynamicreport',
  components: {
    Datatable,
    Multiselect,
    // TableHeaderChange,
  },
  data() {
    return {
      moment: '' as any,
      selectedDistricts: [] as any,
      value: null,
      options: [],
      batch: {},
      employee: {},
      details: {},
      selected: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourseType: [],
      selectedCourse: [],
      selectedDistrict: [],
      updatedArray: [] as any,
      tableData: [] as any,
      tableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
          width: '5px',
          selected: true,
        },
        {
          name: 'Entity',
          key: 'entity',
          sortable: true,
          selected: true,
        },
        {
          name: 'Institute Type',
          key: 'institute_type',
          sortable: true,
        },
        {
          name: 'Short Name',
          key: 'short_name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Institute Name',
          key: 'name',
          sortable: true,
          selected: true,
        },
        {
          name: 'Registration Number',
          key: 'reg_number',
          sortable: true,
        },
        {
          name: 'Registration Date',
          key: 'reg_date',
          sortable: true,
        },
        {
          name: 'Registration Authority',
          key: 'reg_authority',
          sortable: true,
        },
        {
          name: 'Present Address',
          key: 'present_add',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Present City',
          key: 'present_city',
          sortable: true,
        },
        {
          name: 'Present Post Code',
          key: 'postCode',
          sortable: true,
        },
        {
          name: 'Present District',
          key: 'present_district',
          sortable: true,
        },
        {
          name: 'Present Division',
          key: 'present_division',
          sortable: true,
        },
        {
          name: 'Number Of Training Room',
          key: 'trainning_room_num',
          sortable: true,
        },
        {
          name: 'Training Capacity',
          key: 'trainning_capacity',
          sortable: true,
        },
        {
          name: 'Total Trainer Male',
          key: 'male',
          sortable: true,
        },
        {
          name: 'Total Trainer Female',
          key: 'female',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Web Url',
          key: 'web_url',
          sortable: true,
        },
        {
          name: 'Lab Equipment Information',
          key: 'lab_equipment',
          sortable: true,
        },
        {
          name: 'Active Status',
          key: 'active_status',
          sortable: true,
        },
      ] as any,
      tranceInfos: [
        {
          id: '1',
          name: 'Tranche-1',
        },
        {
          id: '2',
          name: 'Tranche-2',
        },
        {
          id: '3',
          name: 'Tranche-3',
        },
        {
          id: '4',
          name: 'Tranche-4',
        },
      ],
      courseTypes: [
        {
          id: '1',
          name: 'New Entrant',
        },
        {
          id: '2',
          name: 'Up Skill',
        },
        {
          id: '3',
          name: 'Up Skill - Special (70-30)',
        },
        {
          id: '4',
          name: 'New Entrant - Special (70-30)',
        },
      ],
      courseInfos: [
        {
          id: 1,
          name: 'Welding & Fabrication (NE) ',
          code: 'W&F (NE)',
          type: 'New Entrant',
          Mgt: 'Yes ',
          month: '3',
          trance: 'TR-1 ',
          unit_cost: '43413',
        },
        {
          id: 2,
          name: 'Electrical & Navigation Equipment Installation (NE) ',
          code: 'E&N (NE) ',
          type: 'New Entrant',
          Mgt: 'Yes',
          month: '3',
          trance: 'TR-1 ',
          unit_cost: '31674',
        },
      ],
      districtData: [] as any,
      TpartnerData: [] as any,
      TranceData: [] as any,
      CourseTypeData: [] as any,
      CourseListData: [] as any,
      districtlabel: [] as any,
      TpartnerLabel: [] as any,
      TrancheLabel: [] as any,
      CourseLabel: [] as any,
      CourseListLabel: [] as any,
      districtInfos: [] as any,
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      CourseTypeInfos: [] as any,
      CourseListInfos: [] as any,
      optionsDistrict: [] as any,
      optionsTPartner: [] as any,
      optionsTranche: [] as any,
      optionsCourseType: [] as any,
      optionsCourseList: [] as any,
      st: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      pdfHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
      $refs: {} as any,
    };
  },
  async created() {
    await this.getDistrict();
    await this.getAssociation();
    await this.getTranche();
    await this.getCourseTypeList();
    this.moment = moment;
    // await this.getallCourse();
  },
  methods: {
    resetData() {
      this.tableData = [] as any;
      this.pdfHeader = [] as any;
      this.districtData = [] as any;
      this.TranceData = [] as any;
      this.TpartnerData = [] as any;
      this.CourseTypeData = [] as any;
      this.CourseListData = [] as any;
      this.districtlabel = [] as any;
      this.TpartnerLabel = [] as any;
      this.TrancheLabel = [] as any;
      this.CourseLabel = [] as any;
      this.CourseListLabel = [] as any;
      this.st = [] as any;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async getCourseTypeList() {
      this.load = true;
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.CourseTypeInfos = response.data.data;
          this.CourseTypeInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.type_name,
            };
            this.optionsCourseType.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // async getallCourse() {
    //   await ApiService.get("course/list")
    //     .then((response) => {
    //       this.CourseListInfos = response.data.data;
    //       // console.log(this.CourseListInfos);
    //       this.CourseListInfos.forEach((item, index) => {
    //         let obj = {
    //           value: item.id,
    //           label: item.course_name,
    //         };
    //         this.optionsCourseList.push(obj);
    //       });
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    async getAssociation() {
      this.load = true;
      await ApiService.get('entity/list')
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    changeData(data) {
      this.districtlabel = [];
      data.map((item, index) => {
        this.optionsDistrict.forEach((item2, index2) => {
          if (item2.value == item) {
            this.districtlabel.push(item2.label);
          }
        });
      });
    },
    changePartnerData(data) {
      this.getCourseListData();
      this.TpartnerLabel = [];
      data.map((item, index) => {
        this.optionsTPartner.forEach((item2, index2) => {
          if (item2.value == item) {
            this.TpartnerLabel.push(item2.label);
          }
        });
      });
    },
    changeTrancheData(data) {
      // this.getCourseListData();

      this.TrancheLabel = [];
      data.map((item, index) => {
        this.optionsTranche.forEach((item2, index2) => {
          if (item2.value == item) {
            this.TrancheLabel.push(item2.label);
          }
        });
        console.log(this.TrancheLabel);
      });
    },
    async downloadPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('courseType', this.CourseTypeData);
      formData.set('course', this.CourseListData);
      formData.set('district', this.districtData);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('institute/dynamic_institute_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async exportTrainee() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('tranche', this.TranceData);
      formData.set('courseType', this.CourseTypeData);
      formData.set('course', this.CourseListData);
      formData.set('district', this.districtData);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/institute/dynamic_institute_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'DynamicInstituteReport.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },

    changeCourseTypeData(data) {
      this.getCourseListData();

      this.CourseLabel = [];
      // console.log(this.optionsCourseType);
      data.map((item, index) => {
        this.optionsCourseType.forEach((item2, index2) => {
          if (item2.value == item) {
            this.CourseLabel.push(item2.label);
          }
        });
      });
      // console.log(this.CourseLabel);
    },
    async getCourseListData() {
      // console.log(this.TpartnerData, this.TranceData, this.CourseTypeData);

      await ApiService.get(
        'course/filterCourse?entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&courseType=' +
        this.CourseTypeData
      )
        .then((response) => {
          this.CourseListInfos = [] as any;
          this.CourseListInfos = response.data.data;

          console.log(JSON.stringify(this.CourseListInfos));
          this.optionsCourseList = [] as any;
          this.CourseListInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: `${item.course_name}-${item.code}`,
              // label: ``,
            };

            this.optionsCourseList.push(obj);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    changeCourseListData(data) {
      this.CourseListLabel = [];
      // console.log(this.optionsCourseType);
      data.map((item, index) => {
        this.optionsCourseList.forEach((item2, index2) => {
          if (item2.value == item) {
            this.CourseListLabel.push(item2.label);
          }
        });
      });
      // console.log(this.CourseLabel);
    },

    async getDistrict() {
      this.load = true;
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.districtInfos = response.data.data;

          this.districtInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.district_name_eng,
            };
            this.optionsDistrict.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    getHeaderChangeData(value) {
      console.log(value);
    },

    async generateInstituteReport() {
      this.load = true;
      await ApiService.get(
        'institute/filterList?entity=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&courseType=' +
        this.CourseTypeData +
        '&course=' +
        this.CourseListData +
        '&district=' +
        this.districtData
      ).then((response) => {
        this.headerSelect = true;
        this.selectedTableHeader();
        this.tableData = response.data.data;

        // for (let i = 0; i < response.data.data.length; i++) {
        //   response.data.data[i].entities = [
        //     ...new Set(response.data.data[i]?.entities),
        //   ];
        // }
        // console.log(JSON.stringify(response.data.data.entities));

        this.componentTableKey += 1;
        this.load = false;
      });
    },
    async getEntityInfos() {
      await ApiService.get('entity/infos')
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get('entity/types')
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    headerFilter() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
      // this.tableHeader.map((item) => {
      //   if (item.selected) {
      //     this.st.push(
      //       JSON.parse(JSON.stringify({ name: item.name, key: item.key }))
      //     );
      //   } else {
      //   }
      // });
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      web_url: Yup.string().required().label('Web Url'),
      postcode: Yup.string().required().max(4).label('Postcode'),
      registration_number: Yup.string().required().label('Registration Number'),
      registration_authority: Yup.string()
        .required()
        .label('Registration Authority'),
      telephone: Yup.number().required().min(11).label('Telephone'),
      entity_name: Yup.string().required().label('Name'),
      entity_short_name: Yup.string().required().label('Short Name'),

      employeeEmail: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label('Name'),
      designation: Yup.string().required().label('Designation'),
      gender: Yup.string().required().label('Gender'),
      mobile: Yup.number().required().min(11).label('Mobile'),
      dob: Yup.string().required().label('Date of Birth'),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .label('Password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Password didn't match!")
        .label('Confirm Password'),
    });
    return {
      AssociationSchema,
    };
  },
});
